/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import t from "../../Viewpoint.js";
import e from "../../core/Collection.js";
import { deg2rad as r } from "../../core/mathUtils.js";
import { getMetersPerUnitForSR as n } from "../../core/unitUtils.js";
import { toRadian as o } from "../../core/libs/gl-matrix-2/math/common.js";
import { fromScaling as a, rotate as i, translate as c, fromTranslation as s, scale as u, fromRotation as l, invert as f } from "../../core/libs/gl-matrix-2/math/mat2d.js";
import { create as m } from "../../core/libs/gl-matrix-2/factories/mat2df64.js";
import { scale as y, set as p, sub as g, negate as x, subtract as h, normalize as b, cross as w, dot as d, length as j, add as G, transformMat2d as R, copy as A } from "../../core/libs/gl-matrix-2/math/vec2.js";
import { fromValues as S, create as k } from "../../core/libs/gl-matrix-2/factories/vec2f64.js";
import M from "../../geometry/Extent.js";
import v from "../../geometry/Geometry.js";
import z from "../../geometry/Point.js";
import { isLoaded as C, canProjectWithoutEngine as F, load as N, project as I } from "../../geometry/projection.js";
import V from "../../geometry/SpatialReference.js";
import { getClosestDenormalizedXToReference as q, getDenormalizedExtent as L } from "../../geometry/support/normalizeUtils.js";
import { equals as P, isValid as U, getInfo as E } from "../../geometry/support/spatialReferenceUtils.js";
const O = 96,
  Q = 39.37,
  T = 180 / Math.PI;
function B(t) {
  return t.wkid ? t : t.spatialReference || V.WGS84;
}
function D(t, e) {
  return e.type ? p(t, e.x, e.y) : A(t, e);
}
function W(t) {
  return n(t);
}
function H(t, e, n = 0) {
  let o = t.width,
    a = t.height;
  if (0 !== n) {
    const e = r(n),
      i = Math.abs(Math.cos(e)),
      c = Math.abs(Math.sin(e));
    o = t.width * i + t.height * c, a = t.width * c + t.height * i;
  }
  const i = Math.max(1, e[0]),
    c = Math.max(1, e[1]);
  return Math.max(o / i, a / c) * ct(t.spatialReference);
}
async function J(t, r, n, o) {
  let a, i;
  if (!t) return null;
  if (Array.isArray(t) && !t.length) return null;
  if (e.isCollection(t) && (t = t.toArray()), Array.isArray(t) && t.length && "object" == typeof t[0]) {
    const e = t.every(t => "attributes" in t),
      a = t.some(t => !t.geometry);
    let i = t;
    if (e && a && r && r.allLayerViews) {
      const e = new Map();
      for (const r of t) {
        const t = r.layer,
          n = e.get(t) || [],
          o = r.attributes[t.objectIdField];
        null != o && n.push(o), e.set(t, n);
      }
      const n = [];
      e.forEach((t, e) => {
        const o = r.allLayerViews.find(t => t.layer.id === e.id);
        if (o && "queryFeatures" in o) {
          const r = e.createQuery();
          r.objectIds = t, r.returnGeometry = !0, n.push(o.queryFeatures(r));
        }
      });
      const o = await Promise.all(n),
        a = [];
      for (const t of o) if (t && t.features && t.features.length) for (const e of t.features) null != e.geometry && a.push(e.geometry);
      i = a;
    }
    for (const t of i) o = await J(t, r, n, o);
    return o;
  }
  if (Array.isArray(t) && 2 === t.length && "number" == typeof t[0] && "number" == typeof t[1]) a = new z(t);else if (t instanceof v) a = t;else if ("geometry" in t) if (t.geometry) a = t.geometry;else if (t.layer) {
    const e = t.layer,
      n = r.allLayerViews.find(t => t.layer.id === e.id);
    if (n && "queryFeatures" in n) {
      const r = e.createQuery();
      r.objectIds = [t.attributes[e.objectIdField]], r.returnGeometry = !0;
      const o = await n.queryFeatures(r);
      a = o?.features?.[0]?.geometry;
    }
  }
  if (null == a) return null;
  switch (a.type) {
    case "point":
      i = new M({
        xmin: a.x,
        ymin: a.y,
        xmax: a.x,
        ymax: a.y,
        spatialReference: a.spatialReference
      });
      break;
    case "extent":
    case "multipoint":
    case "polygon":
    case "polyline":
      i = L(a);
      break;
    default:
      i = a.extent;
  }
  if (!i) return null;
  C() || F(i.spatialReference, n) || (await N());
  const c = I(i, n);
  if (!c) return null;
  if (o) {
    const t = c.center,
      e = t.clone();
    e.x = q(t.x, o.center.x, n), e.x !== t.x && c.centerAt(e), o = o.union(c);
  } else o = c;
  return o;
}
function K(t) {
  if (t && (!Array.isArray(t) || "number" != typeof t[0]) && ("object" == typeof t || Array.isArray(t) && "object" == typeof t[0])) {
    if ("layer" in t && null != t.layer?.minScale && null != t.layer.maxScale) {
      const e = t.layer;
      return {
        min: e.minScale,
        max: e.maxScale
      };
    }
    if (Array.isArray(t) && t.length && t.every(t => "layer" in t)) {
      let e = 0,
        r = 0;
      for (const n of t) {
        const t = n.layer;
        t?.minScale && t.maxScale && (e = t.minScale < e ? t.minScale : e, r = t.maxScale > r ? t.maxScale : r);
      }
      return e && r ? {
        min: e,
        max: r
      } : null;
    }
  }
}
function X(t, e) {
  const r = B(t);
  return P(r, e) || r.imageCoordinateSystem || e.imageCoordinateSystem ? t : I(t, e);
}
async function Y(e, r) {
  if (!e || !r) return new t({
    targetGeometry: new z(),
    scale: 0,
    rotation: 0
  });
  let n = r.spatialReference;
  const {
      constraints: o,
      padding: a,
      viewpoint: i,
      size: c
    } = r,
    s = [a ? c[0] - a.left - a.right : c[0], a ? c[1] - a.top - a.bottom : c[1]];
  let u = null;
  e instanceof t ? u = e : e.viewpoint ? u = e.viewpoint : e.target && "esri.Viewpoint" === e.target.declaredClass && (u = e.target);
  let l = null;
  u?.targetGeometry ? l = u.targetGeometry : e instanceof M ? l = e : e instanceof v ? l = await J(e, r, n) : e && (l = (await J(e.center, r, n)) || (await J(e.target, r, n)) || (await J(e, r, n))), !l && i?.targetGeometry ? l = i.targetGeometry : !l && r.extent && (l = r.extent), n || (n = B(r.spatialReference || r.extent || l)), C() || P(l.spatialReference, n) || F(l.spatialReference, n) || (await N());
  const f = X(l, n),
    m = "center" in f ? f.center : f;
  !1 !== r.pickClosestTarget && "point" === m.type && "point" === i.targetGeometry?.type && (m.x = q(m.x, i.targetGeometry.x, m.spatialReference));
  let y = 0;
  u ? y = u.rotation : e.hasOwnProperty("rotation") ? y = e.rotation : i && (y = i.rotation);
  let p = 0;
  p = null != u?.targetGeometry && "point" === u.targetGeometry.type ? u.scale : "scale" in e && e.scale ? e.scale : "zoom" in e && -1 !== e.zoom && o && o.effectiveLODs ? o.zoomToScale(e.zoom) : Array.isArray(l) || "point" === l.type || "extent" === l.type && 0 === l.width && 0 === l.height ? i.scale : H(X(l.extent, n), s, y);
  const g = K(e.target ?? e);
  g && (g.min && g.min < p ? p = g.min : g.max && g.max > p && (p = g.max));
  let x = new t({
    targetGeometry: m,
    scale: p,
    rotation: y
  });
  return o && (x = o.fit(x), o.constrainByGeometry(x), o.rotationEnabled || (x.rotation = i.rotation)), x;
}
function Z(t, e) {
  const r = t.targetGeometry,
    n = e.targetGeometry;
  return r.x = n.x, r.y = n.y, r.spatialReference = n.spatialReference, t.scale = e.scale, t.rotation = e.rotation, t;
}
function $(t, e, r) {
  return r ? p(t, .5 * (e[0] - r.right + r.left), .5 * (e[1] - r.bottom + r.top)) : y(t, e, .5);
}
const _ = function () {
  const t = k();
  return function (e, r, n) {
    const o = r.targetGeometry;
    D(t, o);
    const a = .5 * ot(r);
    return e.xmin = t[0] - a * n[0], e.ymin = t[1] - a * n[1], e.xmax = t[0] + a * n[0], e.ymax = t[1] + a * n[1], e.spatialReference = o.spatialReference, e;
  };
}();
function tt(t, e, r, n, o) {
  return xt(t, e, r.center), t.scale = H(r, n), o?.constraints?.constrain(t), t;
}
function et(t, e, r, n) {
  return lt(t, e, r, n), f(t, t);
}
const rt = function () {
    const t = k();
    return function (e, r, n) {
      return g(e, st(e, r), $(t, r, n));
    };
  }(),
  nt = function () {
    const t = m(),
      e = k();
    return function (r, n, o, s) {
      const u = ot(n),
        l = it(n);
      return p(e, u, u), a(t, e), i(t, t, l), c(t, t, rt(e, o, s)), c(t, t, [0, s.top - s.bottom]), p(r, t[4], t[5]);
    };
  }();
function ot(t) {
  return t.scale * at(t.targetGeometry?.spatialReference);
}
function at(t) {
  return null != t && U(t) ? 1 / (W(t) * Q * O) : 1;
}
function it(t) {
  return o(t.rotation) || 0;
}
function ct(t) {
  return U(t) ? W(t) * Q * O : 1;
}
function st(t, e) {
  return y(t, e, .5);
}
const ut = function () {
    const t = k(),
      e = k(),
      r = k();
    return function (n, o, a, l, f, m) {
      return x(t, o), y(e, a, .5 * m), p(r, 1 / l * m, -1 / l * m), s(n, e), f && i(n, n, f), u(n, n, r), c(n, n, t), n;
    };
  }(),
  lt = function () {
    const t = k();
    return function (e, r, n, o) {
      const a = ot(r),
        i = it(r);
      return D(t, r.targetGeometry), ut(e, t, n, a, i, o);
    };
  }(),
  ft = function () {
    const t = k();
    return function (e, r, n, o) {
      const a = ot(r);
      return D(t, r.targetGeometry), ut(e, t, n, a, 0, o);
    };
  }();
function mt(t) {
  const e = E(t);
  return e ? e.valid[1] - e.valid[0] : 0;
}
function yt(t, e) {
  return Math.round(mt(t) / e);
}
const pt = function () {
    const t = k(),
      e = k(),
      r = [0, 0, 0];
    return function (n, o, a) {
      h(t, n, o), b(t, t), h(e, n, a), b(e, e), w(r, t, e);
      let i = Math.acos(d(t, e) / (j(t) * j(e))) * T;
      return r[2] < 0 && (i = -i), isNaN(i) && (i = 0), i;
    };
  }(),
  gt = function () {
    const t = k();
    return function (e, r, n, o) {
      const a = e.targetGeometry;
      return Z(e, r), nt(t, r, n, o), a.x += t[0], a.y += t[1], e;
    };
  }(),
  xt = function (t, e, r) {
    Z(t, e);
    const n = t.targetGeometry;
    return n.x = r.x, n.y = r.y, n.spatialReference = r.spatialReference, t;
  },
  ht = function () {
    const t = k();
    return function (e, r, n, o, a) {
      a || (a = "center"), g(t, n, o), y(t, t, .5);
      const i = t[0],
        c = t[1];
      switch (a) {
        case "center":
          p(t, 0, 0);
          break;
        case "left":
          p(t, -i, 0);
          break;
        case "top":
          p(t, 0, c);
          break;
        case "right":
          p(t, i, 0);
          break;
        case "bottom":
          p(t, 0, -c);
          break;
        case "top-left":
          p(t, -i, c);
          break;
        case "bottom-left":
          p(t, -i, -c);
          break;
        case "top-right":
          p(t, i, c);
          break;
        case "bottom-right":
          p(t, i, -c);
      }
      return kt(e, r, t), e;
    };
  }();
function bt(t, e, r) {
  return Z(t, e), t.rotation += r, t;
}
function wt(t, e, r) {
  return Z(t, e), t.rotation = r, t;
}
const dt = function () {
  const t = k();
  return function (e, r, n, o, a) {
    return Z(e, r), isNaN(n) || 0 === n || (At(t, o, r, a), e.scale = r.scale * n, St(t, t, e, a), kt(e, e, p(t, t[0] - o[0], o[1] - t[1]))), e;
  };
}();
function jt(t, e, r) {
  return Z(t, e), t.scale = r, t;
}
const Gt = function () {
    const t = k();
    return function (e, r, n, o, a, i) {
      return Z(e, r), isNaN(n) || 0 === n || (At(t, a, r, i), e.scale = r.scale * n, e.rotation += o, St(t, t, e, i), kt(e, e, p(t, t[0] - a[0], a[1] - t[1]))), e;
    };
  }(),
  Rt = function () {
    const t = k(),
      e = k();
    return function (r, n, o, a, i, c, s) {
      return rt(e, c, s), G(t, i, e), a ? Gt(r, n, o, a, t, c) : dt(r, n, o, t, c);
    };
  }(),
  At = function () {
    const t = m();
    return function (e, r, n, o) {
      return R(e, r, et(t, n, o, 1));
    };
  }(),
  St = function () {
    const t = m();
    return function (e, r, n, o) {
      return R(e, r, lt(t, n, o, 1));
    };
  }(),
  kt = function () {
    const t = k(),
      e = m();
    return function (r, n, o) {
      Z(r, n);
      const a = ot(n),
        i = r.targetGeometry;
      return l(e, it(n)), u(e, e, S(a, a)), R(t, o, e), i.x += t[0], i.y += t[1], r;
    };
  }();
export { gt as addPadding, pt as angleBetween, xt as centerAt, Z as copy, Y as create, H as extentToScale, $ as getAnchor, _ as getExtent, ut as getMatrix, nt as getPaddingMapTranslation, rt as getPaddingScreenTranslation, ot as getResolution, ct as getResolutionToScaleFactor, at as getScaleToResolutionFactor, lt as getTransform, ft as getTransformNoRotation, yt as getWorldScreenWidth, mt as getWorldWidth, Rt as padAndScaleAndRotateBy, ht as resize, bt as rotateBy, wt as rotateTo, Gt as scaleAndRotateBy, jt as scaleTo, tt as setExtent, At as toMap, St as toScreen, kt as translateBy };