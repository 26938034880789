/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "./chunks/tslib.es6.js";
import { Clonable as r } from "./core/Clonable.js";
import { property as s } from "./core/accessorSupport/decorators/property.js";
import "./core/has.js";
import "./core/Logger.js";
import "./core/RandomLCG.js";
import { subclass as e } from "./core/accessorSupport/decorators/subclass.js";
let t = class extends r {
  constructor(o) {
    super(o), this.row = 0, this.column = 0, this.rows = 1, this.columns = 1;
  }
  equals(o) {
    return null != o && this.row === o.row && this.rows === o.rows && this.column === o.column && this.columns === o.columns;
  }
};
o([s({
  type: Number,
  nonNullable: !0,
  json: {
    read: !1,
    write: !1
  }
})], t.prototype, "row", void 0), o([s({
  type: Number,
  nonNullable: !0,
  json: {
    read: !1,
    write: !1
  }
})], t.prototype, "column", void 0), o([s({
  type: Number,
  nonNullable: !0,
  json: {
    read: !1,
    write: !1
  }
})], t.prototype, "rows", void 0), o([s({
  type: Number,
  nonNullable: !0,
  json: {
    read: !1,
    write: !1
  }
})], t.prototype, "columns", void 0), t = o([e("esri.CameraLayout")], t);
const l = t;
export { l as default };