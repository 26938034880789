/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "./chunks/tslib.es6.js";
import r from "./CameraLayout.js";
import { ClonableMixin as t } from "./core/Clonable.js";
import { cyclicalDegrees as e } from "./core/Cyclical.js";
import { JSONSupport as s } from "./core/JSONSupport.js";
import { clamp as i } from "./core/mathUtils.js";
import { property as p } from "./core/accessorSupport/decorators/property.js";
import { cast as n } from "./core/accessorSupport/decorators/cast.js";
import "./core/RandomLCG.js";
import "./core/has.js";
import { reader as a } from "./core/accessorSupport/decorators/reader.js";
import { subclass as l } from "./core/accessorSupport/decorators/subclass.js";
import { writer as c } from "./core/accessorSupport/decorators/writer.js";
import { ensureNumber as u } from "./core/accessorSupport/ensureType.js";
import m from "./geometry/Point.js";
let y = class extends t(s) {
  constructor(...o) {
    super(...o), this.position = new m([0, 0, 0]), this.heading = 0, this.tilt = 0, this.fov = 55, this.layout = new r();
  }
  normalizeCtorArgs(o, r, t, e) {
    if (o && "object" == typeof o && ("x" in o || Array.isArray(o))) {
      const s = {
        position: o
      };
      return null != r && (s.heading = r), null != t && (s.tilt = t), null != e && (s.fov = e), s;
    }
    return o;
  }
  writePosition(o, r, t, e) {
    const s = o.clone();
    s.x = u(o.x || 0), s.y = u(o.y || 0), s.z = o.hasZ ? u(o.z || 0) : o.z, r[t] = s.write({}, e);
  }
  readPosition(o, r) {
    const t = new m();
    return t.read(o, r), t.x = u(t.x || 0), t.y = u(t.y || 0), t.z = t.hasZ ? u(t.z || 0) : t.z, t;
  }
  equals(o) {
    return null != o && this.tilt === o.tilt && this.heading === o.heading && this.fov === o.fov && this.position.equals(o.position) && this.layout.equals(o.layout);
  }
};
o([p({
  type: m,
  json: {
    write: {
      isRequired: !0
    }
  }
})], y.prototype, "position", void 0), o([c("position")], y.prototype, "writePosition", null), o([a("position")], y.prototype, "readPosition", null), o([p({
  type: Number,
  nonNullable: !0,
  json: {
    write: {
      isRequired: !0
    }
  }
}), n(o => e.normalize(u(o)))], y.prototype, "heading", void 0), o([p({
  type: Number,
  nonNullable: !0,
  json: {
    write: {
      isRequired: !0
    }
  }
}), n(o => i(u(o), -180, 180))], y.prototype, "tilt", void 0), o([p({
  type: Number,
  nonNullable: !0,
  json: {
    default: 55,
    write: !0
  }
}), n(o => i(u(o, 55), 1, 170))], y.prototype, "fov", void 0), o([p({
  type: r,
  nonNullable: !0,
  json: {
    read: !1,
    write: !1
  }
})], y.prototype, "layout", void 0), y = o([l("esri.Camera")], y);
const d = y;
export { d as default };