/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as r } from "./chunks/tslib.es6.js";
import t from "./Camera.js";
import { geometryTypes as e } from "./geometry.js";
import { JSONSupport as o } from "./core/JSONSupport.js";
import { property as s } from "./core/accessorSupport/decorators/property.js";
import { cast as i } from "./core/accessorSupport/decorators/cast.js";
import "./core/RandomLCG.js";
import "./core/has.js";
import { subclass as a } from "./core/accessorSupport/decorators/subclass.js";
import { fromJSON as c } from "./geometry/support/jsonUtils.js";
var n;
let p = n = class extends o {
  constructor(r) {
    super(r), this.rotation = 0, this.scale = 0, this.targetGeometry = null, this.camera = null;
  }
  castRotation(r) {
    return (r %= 360) < 0 && (r += 360), r;
  }
  clone() {
    return new n({
      rotation: this.rotation,
      scale: this.scale,
      targetGeometry: null != this.targetGeometry ? this.targetGeometry.clone() : null,
      camera: null != this.camera ? this.camera.clone() : null
    });
  }
};
r([s({
  type: Number,
  json: {
    write: !0,
    origins: {
      "web-map": {
        default: 0,
        write: !0
      },
      "web-scene": {
        write: {
          overridePolicy: l
        }
      }
    }
  }
})], p.prototype, "rotation", void 0), r([i("rotation")], p.prototype, "castRotation", null), r([s({
  type: Number,
  json: {
    write: !0,
    origins: {
      "web-map": {
        default: 0,
        write: !0
      },
      "web-scene": {
        write: {
          overridePolicy: l
        }
      }
    }
  }
})], p.prototype, "scale", void 0), r([s({
  types: e,
  json: {
    read: c,
    write: !0,
    origins: {
      "web-scene": {
        read: c,
        write: {
          overridePolicy: l
        }
      }
    }
  }
})], p.prototype, "targetGeometry", void 0), r([s({
  type: t,
  json: {
    write: !0
  }
})], p.prototype, "camera", void 0), p = n = r([a("esri.Viewpoint")], p);
const m = p;
function l() {
  return {
    enabled: !this.camera
  };
}
export { m as default };